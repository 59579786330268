const itemsToFind = [
  { name: "American football", fileName: "american-football-311817_640.png" },
  { name: "Carrot", fileName: "carrot-5982724_640.png" },
  { name: "Hamburger", fileName: "hamburger-31775_640.png" },
  { name: "Pack of fries", fileName: "french-fries-155679_640.png" },
  {
    name: "Pineapple",
    fileName: "pngkey.com-pineapple-clipart-png-9605931.png",
  },
  { name: "Pizza", fileName: "pngkey.com-pizza-png-53164.png" },
];

export default itemsToFind;
